import { graphql, Link, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import { Alert, Col, Row } from "react-bootstrap";
import HardwareBase from "../../components/hardware-base";
import { DASHBOARD_URL, BRAND_NAME } from "../../util/constants";
import Gist from 'react-gist';

const RaspberryPiSetup = () => {
  const q = useStaticQuery(graphql`
    query {
      fireTv: file(relativePath: { eq: "help/firetv.png" }) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fluid(maxWidth: 900, quality: 95) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      installScreen: file(
        relativePath: { eq: "help/firetv-install-screen.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 900, quality: 95) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      setupPairing: file(relativePath: { eq: "help/setup-pairing.png" }) {
        childImageSharp {
          fluid(maxWidth: 900, quality: 95) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      setupComplete: file(relativePath: { eq: "help/setup-complete.png" }) {
        childImageSharp {
          fluid(maxWidth: 900, quality: 95) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <HardwareBase platformName="Raspberry Pi">
      <Row>
        <Col>
          <p className="lead">
            If you've got a Raspberry Pi, you can quickly turn it into a digital
            beer and beverage menu using Taplist.io. Just follow these
            instructions
          </p>
          <Alert variant="warning">
            Although Raspberry Pi works great, these instructions are geared
            towards more advanced users. If you're just getting started with{' '}
            Taplist.io, we strongly recommend the{" "}
            <Link to={`/help/fire-tv-setup`}>Fire TV setup</Link> instead.
          </Alert>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col>
          <h3>Requirements</h3>
          <p>Before we begin, we'll assume you have the following:</p>
          <ul>
            <li>A Raspberry Pi version 3 or 4</li>
            <li>Running Raspbian or Raspberry Pi OS</li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col>
          <h3>Step 1: Install Chromium</h3>
          <p>
            Taplist.io takes advantage of the powerful HTML5 rendering
            engine found in many browsers. On Raspberry Pi, we will use a
            "kiosk" version of the Chromium browser to turn your Pi into an
            awesome full screen display.
          </p>
          <p>
            Let's install Chromium on your Pi by{" "}
            <a href="https://www.raspberrypi.org/forums/viewtopic.php?t=121195">
              following these instructions from the Raspberry Pi forums.
            </a>{" "}
            Paste the following commands into your Pi:
          </p>
          <Gist id="mik3y/9f868db7ad7054806d456c8ecd16d11d" />
        </Col>
      </Row>
      <Row>
        <Col>
          <h3>Step 2: Configure Taplist.io To Auto Launch</h3>
          <p>
            Once Chromium is installed, we'll configure the Raspbian desktop
            (GNOME) to automatically load the Taplist.io display page on
            launch. Copy and paste the following commands into your pi to create
            a new launcher script:
          </p>
          <Gist id="mik3y/9f8378e99b70a6f89d952693cd864989" />
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <h3>Step 3: Reboot &amp; Pair Your Display</h3>
          <p>
            Confirm everything worked by restarting your Pi. Shortly after
            startup, you should see the browser load.
          </p>
          <p>
            The first time Taplist.io loads, you will see a pairing
            screen similar to the screenshot here. This pairing process securely
            links your new display to your Taplist.io account.
          </p>
          <p>
            Using your mobile phone, laptop, or any other device, visit the
            Taplist.io <a href="/activate">activation page</a> and enter
            the code shown on screen.
          </p>
        </Col>
        <Col>
          <Img
            fluid={q.setupPairing.childImageSharp.fluid}
            alt={`The Taplist.io pairing screen`}
          />
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <h3>Done!</h3>
          <p>
            You don't have to to anything else once your device has been
            successfully paired! You should now see your taps on screen.
          </p>
          <p>
            The Fire TV app is designed to be "set it and forget": You
            completely manage and control all aspects of your new display
            through the{"  "}
            <a href={DASHBOARD_URL}>Taplist.io Dashboard</a>, not the
            device itself.
          </p>
        </Col>
        <Col>
          <Img
            fluid={q.setupComplete.childImageSharp.fluid}
            alt={`The Taplist.io menu display`}
          />
        </Col>
      </Row>
    </HardwareBase>
  );
};

export default RaspberryPiSetup;
